import React, { useState } from 'react'

import {
  Button,
  useNotify,
  useUnselect,
  useRedirect,
  useDelete,
  useShowContext,
} from 'react-admin'

import {
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const DeleteDialog = ({ onClose, selectedValue, open, reasons }) => {
  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select reason for deletion</DialogTitle>
      <List>
        {Object.values(reasons).map((reason, index) => {
          return (
            <ListItem
              button
              onClick={() => handleListItemClick(reason)}
              key={index}
            >
              <ListItemText primary={reason.reason} />
            </ListItem>
          )
        })}
      </List>
    </Dialog>
  )
}

const RedButton = styled(Button)({
  color: '#f44336',
})

const DeleteButton = ({ reasons, dependencies }) => {
  const { record, resource, isLoading } = useShowContext()
  const unselect = useUnselect(resource)
  const redirect = useRedirect()

  const [deleteOne] = useDelete(
    undefined,
    {},
    {
      onSuccess: (data) => {
        notify('ra.notification.deleted', {
          type: 'info',
          messageArgs: { smart_count: 1 },
          undoable: false,
        })
        unselect([record.id])
        redirect('list', resource)
      },
      onError: (error) => {
        notify(error.message, { type: 'warning' })
      },
    },
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const notify = useNotify()

  if (isLoading) {
    return null
  }

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = async (reason) => {
    setDialogOpen(false)
    if (!reason) {
      return
    }
    deleteOne(resource, {
      id: record.id,
      previousData: record,
      meta: { reason, dependencies },
    })
  }

  return (
    <>
      <DeleteDialog open={dialogOpen} onClose={handleClose} reasons={reasons} />
      <RedButton label="Delete" onClick={handleClickOpen} />
    </>
  )
}

DeleteButton.defaultProps = { reasons: [] }

export default DeleteButton
