import React from 'react'

import { useMutation } from 'react-query'
import { Button, useShowContext, useDataProvider } from 'react-admin'

import { frontendDashboardUrl } from '../config'

const LoginAsUserButton = () => {
  const dataProvider = useDataProvider()
  const { record, isLoading } = useShowContext()
  const { mutate } = useMutation(() => dataProvider.loginAsUser(record.id), {
    onSuccess: () => {
      window.open(frontendDashboardUrl, '_blank').focus()
    },
  })

  if (isLoading) {
    return null
  }

  const handleLoginAsUser = () => {
    mutate()
  }

  return (
    <>
      <Button label="Login as user" onClick={handleLoginAsUser} />
    </>
  )
}

LoginAsUserButton.defaultProps = { reasons: [] }

export default LoginAsUserButton
