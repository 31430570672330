import React from 'react'
import QRCode from 'react-qr-code'

import {
  Button,
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Avatar,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'

// import makeStyles from '@mui/styles/makeStyles'
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core'

const Input = (props) => <TextField variant="standard" {...props} />

const LoginForm = (props) => {
  const { redirectTo } = props

  const [loading, setLoading] = useSafeSetState(false)
  const [otpAuth, setOtpAuth] = useSafeSetState()

  const [email, setEmail] = useSafeSetState('')
  const [password, setPassword] = useSafeSetState('')

  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)

    const data = new FormData(e.target)
    const values = { email, password }
    for (const [name, value] of data) {
      if (value) {
        values[name] = value
      }
    }

    setEmail(values.email)
    setPassword(values.password)

    login(values, redirectTo)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.error === 'need_2factor_set') {
          setOtpAuth(error.otpauth)
          return
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          },
        )
      })
  }

  return (
    <Box component="form" onSubmit={submit} sx={{ p: 1, pt: 0 }}>
      <CardContent>
        <Avatar sx={{ ml: 'auto', mr: 'auto' }}>
          <LockIcon />
        </Avatar>

        {otpAuth && (
          <Box pt={2}>
            <QRCode value={otpAuth} />
          </Box>
        )}

        <Stack spacing={2}>
          {!otpAuth && (
            <>
              <Input name="email" label="Email" autoComplete="email" />
              <Input
                name="password"
                label={translate('ra.auth.password')}
                type="password"
                autoComplete="current-password"
              />
            </>
          )}

          <Input
            name="twoFactorToken"
            label="2FA token"
            type="token"
            autoComplete="off"
          />
        </Stack>
      </CardContent>

      <CardActions>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading && <CircularProgress size={18} thickness={2} />}
          {translate('ra.auth.sign_in')}
        </Button>
      </CardActions>
    </Box>
  )
}

export default LoginForm
