import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  SearchInput,
} from 'react-admin'

const siteFilters = [<SearchInput source="q" alwaysOn />]

const SiteList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'id', order: 'desc' }}
      filters={siteFilters}
      filterDefaultValues={{ user: { isTestUser: false } }}
    >
      <Datagrid rowClick="show">
        <TextField source="key" />
        <TextField source="name" />

        <ReferenceField
          label="User"
          source="userId"
          reference="user"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}

export default SiteList
