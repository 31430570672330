import { LibraryBooks as JoinBetaSubmissionsIcon } from '@mui/icons-material'

import JoinBetaSubmissionList from './joinBetaSubmissionList'

const users = {
  list: JoinBetaSubmissionList,
  icon: JoinBetaSubmissionsIcon,
}

export default users
