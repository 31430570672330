import { stringify } from 'query-string'
import { fetchUtils } from 'ra-core'

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' })
  }

  options.credentials = 'include'

  return fetchUtils.fetchJson(url, options)
}

const setQueryFromParams = (params) => {
  if (!params) {
    return {}
  }
  const query = {}
  if (params.sort) {
    const { field, order } = params.sort
    query.sort = JSON.stringify({ field, order })
  }
  if (params.pagination) {
    const { page, perPage } = params.pagination
    query.range = JSON.stringify([(page - 1) * perPage, page * perPage])
  }
  if (params.filter && Object.keys(params.filter).length) {
    query.filter = JSON.stringify(params.filter)
  }
  return query
}

const filterData = (data) =>
  // TOOD: omit all props that start with _
  omitDeep(data, ['createdAt', 'updatedAt', '_role'])

const getExtraUpdatesAndClean = (data) => {
  return Object.entries(data)
    .filter(([, value]) => isObject(value) && !Array.isArray(value))
    .map(([reference, payload]) => {
      delete data[reference]
      return { reference, payload }
    })
}

const isObject = (obj) =>
  typeof obj === 'object' && obj !== null && !(obj instanceof Date)

// based on https://github.com/jonschlinkert/object.omit/blob/master/index.js
const omitDeep = (obj, props) => {
  if (!isObject(obj)) {
    return obj
  }

  const keys = Object.keys(obj)
  const res = {}

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]
    const val = obj[key]

    if (!props || props.indexOf(key) === -1) {
      if (Array.isArray(val)) {
        res[key] = val.map((v) => omitDeep(v, props))
      } else if (isObject(val)) {
        res[key] = omitDeep(val, props)
      } else {
        res[key] = val
      }
    }
  }
  return res
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const dataProvider = (apiUrl) => {
  return {
    getList: (resource, params) => {
      let baseUrl = `${apiUrl}/${resource}`

      // argh this is so ugly
      if (params?.filter?._getAt) {
        baseUrl = params.filter._getAt
      }
      const query = setQueryFromParams(params)
      query.getRelationIds = true

      const url = `${baseUrl}?${stringify(query)}`

      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.pagination.count,
        }
      })
    },

    getOne: (resource, params) => {
      if (params.id === undefined) {
        return Promise.resolve({ data: {} })
      }
      return httpClient(
        `${apiUrl}/${resource}/${params.id}?getRelationIds=1`,
      ).then(({ json }) => ({
        data: json.result,
      }))
    },

    getMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
        getRelationIds: true,
      }
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return httpClient(url).then(({ json }) => ({ data: json.result }))
    },

    getManyReference: (resource, params) => {
      const query = setQueryFromParams(params)
      query.filter = JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      })
      query.getRelationIds = true
      const url = `${apiUrl}/${resource}?${stringify(query)}`

      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.pagination.count,
        }
      })
    },

    update: (resource, params) => {
      const data = filterData(params.data)
      const extraUpdates = getExtraUpdatesAndClean(data)

      const requests = [
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
        }),
      ]

      // look for related objects that should also be updated
      // for example agencyFeeClaim updates claim.notes
      extraUpdates.forEach(({ reference, payload }) => {
        requests.push(
          httpClient(`${apiUrl}/${reference}/${payload.id}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
          }),
        )
      })
      return Promise.all(requests).then(([{ json }]) => ({ data: json.result }))
    },

    // lumberjack doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(filterData(params.data)),
          }),
        ),
      ).then((responses) => ({
        data: responses.map(({ json }) => json.result.id),
      })),

    create: (resource, params) =>
      httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(filterData(params.data)),
      }).then(({ json }) => ({
        data: json.result,
      })),

    delete: (resource, params) =>
      httpClient(
        `${apiUrl}/${resource}/${params.id}?${stringify({
          ...params.meta.reason,
          dependencies: params.meta.dependencies,
        })}`,
        {
          method: 'DELETE',
        },
      ).then(({ json }) => ({ data: { id: params.id } })),

    // lumberjack doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(
            `${apiUrl}/${resource}/${id}?${stringify({
              ...params.meta.reason,
              dependencies: params.meta.dependencies,
            })}`,
            {
              method: 'DELETE',
            },
          ),
        ),
      ).then((responses) => ({
        data: params.ids,
      })),

    /* custom methods */
    loginAsUser: (userId) => {
      if (userId === undefined) {
        return Promise.reject('userId is required')
      }
      return httpClient(`${apiUrl}/../users/${userId}/loginAs`, {
        method: 'POST',
      })
    },
  }
}

export default dataProvider
