import * as React from 'react'
import get from 'lodash/get'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  ReferenceArrayField,
  Datagrid,
  useRecordContext,
} from 'react-admin'

import { Typography, Chip } from '@mui/material'

const TextArrayField = ({ source }) => {
  const record = useRecordContext()
  const array = get(record, source)
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    )
  }
}
TextArrayField.defaultProps = { addLabel: true }

const SiteTitle = ({ record }) => {
  return <span>Site {record ? `${record.key}` : ''}</span>
}

const SiteShow = (props) => {
  return (
    <Show {...props} title={<SiteTitle />}>
      <SimpleShowLayout>
        <Typography variant="h6">Details</Typography>
        <TextField source="key" />
        <TextField source="name" />
        <TextField source="bunnyPullzoneName" />
        <TextField source="originUrl" />
        <TextField source="importedFrom" />
        <TextField source="cdnHostName" />
        <ReferenceField
          label="User"
          source="userId"
          reference="user"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceArrayField source="linkedHostsIds" reference="host">
          <Datagrid bulkActionButtons={null} hover={false}>
            <TextField source="hostName" />
            <BooleanField source="primary" />
            <BooleanField source="preview" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceArrayField>
        <TextArrayField source="hostsToProxy" />
        <TextArrayField source="hostsToAllow" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  )
}

export default SiteShow
