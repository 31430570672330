const NODE_ENV = process.env.NODE_ENV || 'development'

module.exports = {
  apiUrl:
    NODE_ENV === 'production'
      ? 'https://lj.relayzer.com'
      : 'https://lj.packagedeal.local.appeal',
  frontendDashboardUrl:
    NODE_ENV === 'production'
      ? 'https://app.relayzer.com/dashboard'
      : 'https://packagedeal.local.appeal/dashboard',
}
