import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  SearchInput,
} from 'react-admin'

const userFilters = [
  <BooleanInput label="Show test users" source="isTestUser" alwaysOn />,
  <SearchInput source="q" alwaysOn />,
]

const UserList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'id', order: 'desc' }}
      filters={userFilters}
      filterDefaultValues={{ isTestUser: false }}
    >
      <Datagrid rowClick="show">
        <TextField source="fullName" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}

export default UserList
