import { LibraryBooks as ClaimIcon } from '@mui/icons-material'

import SiteList from './SiteList'
import SiteShow from './SiteShow'

const claims = {
  list: SiteList,
  show: SiteShow,
  icon: ClaimIcon,
  options: {
    label: 'Sites',
  },
}

export default claims
