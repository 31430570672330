import * as React from 'react'
import { List, Datagrid, TextField, DateField } from 'react-admin'

const JoinBetaSubmissionsList = (props) => {
  return (
    <List {...props} perPage={25} sort={{ field: 'id', order: 'desc' }}>
      <Datagrid rowClick={false}>
        <TextField source="email" />
        <TextField source="reason" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}

export default JoinBetaSubmissionsList
