import { LibraryBooks as UserIcon } from '@mui/icons-material'

import UserList from './UserList'
import UserShow from './UserShow'

const users = {
  list: UserList,
  show: UserShow,
  icon: UserIcon,
}

export default users
