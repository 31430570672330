import { Admin, Resource } from 'react-admin'

import dataProvider from './dataProvider'
import authProvider from './authProvider'
import LoginPage from './login'

import theme from './theme'

import sites from './sites'
import users from './users'
import joinBetaSubmissions from './joinBetaSubmissions'

import { apiUrl } from './config'

const App = () => (
  <Admin
    dataProvider={dataProvider(apiUrl + '/api/v1/crud', '')}
    authProvider={authProvider(apiUrl + '/api/v1')}
    loginPage={LoginPage}
    theme={theme}
    disableTelemetry
  >
    <Resource name="user" {...users} />
    <Resource name="site" {...sites} />
    <Resource name="joinBetaSubmission" {...joinBetaSubmissions} />
    <Resource name="host" />
  </Admin>
)

export default App
