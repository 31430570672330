import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'

const theme = merge({}, defaultTheme, {
  palette: {
    secondary: { main: '#574AF0' },
  },
})

export default theme
