import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  DateField,
  TopToolbar,
} from 'react-admin'

import { Typography } from '@mui/material'

import DeleteButton from '../components/DeleteButton'
import LoginAsUserButton from '../components/LoginAsUserButton'

const deleteReasons = [
  {
    reason: 'Users only claim retracted',
    cleanUpAfter: 'P7D',
  },
]
const deleteAlso = [
  'sites',
  'loginLinkToken',
  'passwordResetLinkToken',
  'confirmEmailLinkToken',
  'sessionTokens',
  'userEventLogEntries',
]

const Actions = () => (
  <TopToolbar>
    {/*<EditButton />*/}
    <LoginAsUserButton />
    <DeleteButton reasons={deleteReasons} dependencies={deleteAlso} />
  </TopToolbar>
)

const UserShow = (props) => {
  return (
    <Show actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <Typography variant="h6">Details</Typography>
        <TextField source="fullName" />
        <EmailField source="email" />
        <DateField source="emailVerified" emptyText="no" />
        <DateField source="lastLogin" emptyText="never" />
        <BooleanField source="isTestUser" />

        <ReferenceArrayField source="sitesIds" reference="site">
          <SingleFieldList linkType="show">
            <TextField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  )
}

export default UserShow
