import { httpClient } from './dataProvider'

const authProvider = (apiUrl) => ({
  login: ({ email, password, twoFactorToken }) => {
    // we use request here instead of http client as httpclient
    // automaticly throws errors on any non 200-299 request
    const request = new Request(apiUrl + '/adminAccount/login', {
      method: 'POST',
      body: JSON.stringify({ email, password, twoFactorToken }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((response) => {
        switch (response.statusCode) {
          case 202:
            return Promise.reject({
              error: 'need_2factor_set',
              otpauth: response.result.otpauth,
            })
          case 201:
            const { expiresAt } = response.result
            localStorage.setItem('loggedInTill', new Date(expiresAt).getTime())
            return
          default:
            throw new Error(response.message || 'Unknown Error')
        }
      })
  },
  logout: () => {
    localStorage.removeItem('loggedInTill')
    // no need to wait for the cookie to be removed
    httpClient(apiUrl + '/adminAccount/logout', {
      method: 'POST',
    })
    return Promise.resolve()
  },
  checkAuth: () => {
    const loggedInTill = localStorage.getItem('loggedInTill')
    if (!loggedInTill || new Date(loggedInTill) < new Date()) {
      return Promise.reject({ redirectTo: '/login' })
    }
    return Promise.resolve()
  },
  checkError: ({ message, status, body }) => {
    if (status === 401) {
      // auth error!
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: async () => {
    return []
  },
})

export default authProvider
