import React, { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { Notification } from 'react-admin'

import { Box, Card } from '@mui/material'

import Form from './Form'

const LoginView = (props) => {
  const { backgroundImage } = props
  const containerRef = useRef()

  const checkAuth = useCheckAuth()
  const navigate = useNavigate()

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, navigate])

  let backgroundImageLoaded = false

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image()
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage:
          'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
      }}
      ref={containerRef}
    >
      <Card
        sx={{
          minWidth: 300,
          marginTop: 12,
        }}
      >
        <Form />
      </Card>
      <Notification />
    </Box>
  )
}

export default LoginView
